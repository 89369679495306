<template>
    <div class="vh-lg-100 position-relative">
        <div class="row justify-content-center align-items-center h-100 mx-0">
            <div class="col-lg-8 p-0 h-100">
                <img :src="ImgBG" alt="Iniciar sesión" class="w-100 h-100 img-background">
            </div>
            <div class="col-lg-4 p-0 bg-white h-100">
                <div class="row align-items-center justify-content-center h-100 mx-0">
                    <div class="col-lg-10 py-3">
                        <div class="text-center mb-5" v-if="false">
                            <router-link :to="{name: 'home'}">
                                <img :src="LogoDark" alt="Logo" class="img-fluid">
                            </router-link>
                        </div>
                        <div class="text-center mb-3 mt-5 text-muted">
                            <h1 class="mb-0">
                                Regístrate
                            </h1>
                            <small>
                                Introduce la siguiente información para crear tu cuenta.
                            </small>
                        </div>
                        <form type="POST" v-on:submit.prevent="register" ref="userForm">
                            <div class="row">
                                <div class="col-lg-6 mb-3 pe-1">
                                    <div class="position-relative">
                                        <span class="position-absolute bg-secondary rounded-pill text-white d-flex align-items-center justify-content-center" style="top: 4px; left: 5px; width: 30px; height: 30px;">
                                            <font-awesome-icon :icon="faIdCard" />
                                        </span>
                                        <input type="text" id="nombre-register" class="form-control" placeholder="Nombre" style="padding-left: 42px;" v-model="form.nombres" maxlength="150" required>
                                    </div>
                                    <span v-if="errors.nombres" class="text-danger">
                                        {{errors.nombres[0]}}
                                    </span>
                                </div>
                                <div class="col-lg-6 mb-3 pl-1">
                                    <div class="position-relative">
                                        <span class="position-absolute bg-secondary rounded-pill text-white d-flex align-items-center justify-content-center" style="top: 4px; left: 5px; width: 30px; height: 30px;">
                                            <font-awesome-icon :icon="faIdBadge" />
                                        </span>
                                        <input type="text" id="apellido-register" class="form-control" placeholder="Apellido" style="padding-left: 42px;" v-model="form.apellidos" maxlength="150" required>
                                    </div>
                                    <span v-if="errors.apellidos" class="text-danger">
                                        {{errors.apellidos[0]}}
                                    </span>
                                </div>
                            </div>

                            <div class="mb-3">
                                <div class="position-relative">
                                    <span class="position-absolute bg-secondary rounded-pill text-white d-flex align-items-center justify-content-center" style="top: 4px; left: 5px; width: 30px; height: 30px;">
                                        <font-awesome-icon :icon="faPhone" />
                                    </span>
                                    <input type="tel" id="telefono" class="form-control" placeholder="Teléfono" style="padding-left: 42px;" v-model="form.telefono" maxlength="150" required>
                                </div>
                                <span v-if="errors.telefono" class="text-danger">
                                    {{errors.telefono[0]}}
                                </span>
                            </div>

                            <div class="mb-3" v-if="false">
                                <div class="d-flex align-items-center">
                                    <div class="me-3">
                                        <label class="form-label text-muted">Pareja:</label>
                                    </div>
                                    <div class="me-3">
                                        <label class="border rounded cursor-pointer px-3 py-1" style="border-style: dashed !important;">
                                            <div class="form-check">
                                                <input :class="{'form-check-input':true, 'bg-secondary':form.sexo=='hombre'}" type="radio" name="sexo" id="sexoHombre" value="hombre" v-model="form.sexo" required>
                                                <span class="form-check-label">Novio</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div>
                                        <label class="border rounded cursor-pointer px-3 py-1" style="border-style: dashed !important;">
                                            <div class="form-check">
                                                <input :class="{'form-check-input':true, 'bg-secondary':form.sexo=='mujer'}" type="radio" name="sexo" id="sexoMujer" value="mujer" v-model="form.sexo" required>
                                                <span class="form-check-label">Novia</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <span v-if="errors.sexo" class="text-danger">
                                    {{errors.sexo[0]}}
                                </span>
                            </div>
                            
                            <div class="mb-3">
                                <div class="position-relative">
                                    <span class="position-absolute bg-secondary rounded-pill text-white d-flex align-items-center justify-content-center" style="top: 4px; left: 5px; width: 30px; height: 30px;">
                                        <font-awesome-icon :icon="faEnvelope" />
                                    </span>
                                    <input type="email" id="email-register" class="form-control" placeholder="Correo electrónico" style="padding-left: 42px;" v-model="form.email" maxlength="150" required>
                                </div>
                                <span v-if="errors.email" class="text-danger">
                                    {{errors.email[0]}}
                                </span>
                            </div>
                            
                            <div class="row">
                                <div class="col-lg-6 mb-3 pe-1">
                                    <div class="position-relative">
                                        <span class="position-absolute bg-secondary rounded-pill text-white d-flex align-items-center justify-content-center" id="email-login" style="top: 4px; left: 5px; width: 30px; height: 30px;">
                                            <font-awesome-icon :icon="faLock" />
                                        </span>
                                        <input :type="passwordDF" id="password-register" class="form-control" placeholder="Contraseña" style="padding-left: 42px; padding-right: 42px;" v-model="form.password" maxlength="50" autocomplete="on" required>
                                        <div class="position-absolute" style="right: 5px; top: 3px;">
                                            <button type="button" class="btn btn-outline-light btn-sm border-0 text-muted shadow-0" v-on:click="password=!password" tabindex="-1">
                                                <font-awesome-icon :icon="faEyeSlash" v-if="password" />
                                                <font-awesome-icon :icon="faEye" v-else />
                                            </button>
                                        </div>
                                    </div>
                                    <span v-if="errors.password" class="text-danger">
                                        {{errors.password[0]}}
                                    </span>
                                </div>
                                <div class="col-lg-6 mb-3 pl-1">
                                    <div class="position-relative">
                                        <span class="position-absolute bg-secondary rounded-pill text-white d-flex align-items-center justify-content-center" id="email-login" style="top: 4px; left: 5px; width: 30px; height: 30px;">
                                            <font-awesome-icon :icon="faLockOpen" />
                                        </span>
                                        <input :type="passwordConfirmationDF" id="password-confirmation-register" class="form-control" placeholder="Confirmar contraseña" style="padding-left: 42px; padding-right: 42px;" v-model="form.password_confirmation" maxlength="50" autocomplete="on" required>
                                        <div class="position-absolute" style="right: 5px; top: 3px;">
                                            <button type="button" class="btn btn-outline-light btn-sm border-0 text-muted shadow-0" v-on:click="passwordConfirmation=!passwordConfirmation" tabindex="-1">
                                                <font-awesome-icon :icon="faEyeSlash" v-if="passwordConfirmation" />
                                                <font-awesome-icon :icon="faEye" v-else />
                                            </button>
                                        </div>
                                    </div>
                                    <span v-if="errors.password_confirmation" class="text-danger">
                                        {{errors.password_confirmation[0]}}
                                    </span>
                                    <span v-if="form.password.lenght>0 && form.password != form.password_confirmation" class="text-danger">
                                        No coinciden
                                    </span>
                                </div>
                            </div>

                            <div class="d-grid gap-2 mb-3">
                                <button type="submit" class="btn btn-secondary btn-lg" :disabled="loading || !validateForm">
                                    <BtnLoading v-if="loadingRegister" />
                                    <span v-else>
                                        <font-awesome-icon :icon="faSignInAlt" /> Crear cuenta
                                    </span>
                                </button>
                            </div>
                        </form>
                        <div class="d-grid gap-2 mb-3 border-top pt-3">
                            <router-link :to="{name: 'login'}" class="btn btn-link btn-lg" :disabled="loading">
                                Iniciar sesión
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEnvelope, faPhone, faLock, faLockOpen, faIdBadge, faIdCard, faEyeSlash, faEye, faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import ImgBG from '@/assets/img/backgrounds/register.jpg'
import LogoDark from '@/assets/img/logo_dark.png'
import BtnLoading from '@/components/BtnLoading.vue'
export default {
    name: 'registro',
    components: {
        FontAwesomeIcon,
        BtnLoading
    },
    data(){
        return {
            ImgBG,
            LogoDark,
            faEnvelope,
            faPhone,
            faLock,
            faLockOpen,
            faIdBadge,
            faIdCard,
            faEyeSlash,
            faEye,
            faSignInAlt,
            password: true,
            passwordConfirmation: true,
            loadingRegister: false,
            validateForm: false,
            form: {
                nombres: '',
                apellidos: '',
                email: '',
                telefono: '',
                password: '',
                password_confirmation: '',
                sexo: ''
            }
        }
    },
    mounted(){
        this.$store.commit('showMenu', false)
        this.$store.commit('bgFooter', 'bg-secondary text-dark')
    },
    unmounted(){
        this.$store.commit('showMenu', true)
        this.$store.commit('bgFooter', '')
    },
    methods: {
        register(){
            this.checkValidity()
            if (!this.validateForm) {
                return
            }
            this.loadingRegister = true
            this.$store.dispatch('register', this.form).then((res) => {
                this.login({
                    email: this.form.email,
                    password: this.form.password
                })
                return res
            }).catch(err => {
                if(err.response){
                    if(typeof err.response.data === 'string'){
                        this.$toast.error(err.response.data)
                    }else{
                        this.$toast.error('Error en el registro')
                    }
                }else{
                    console.log(err)
                }
            }).then(() => {
                this.loadingRegister = false
            })
        },
        login(form){
            this.$store.dispatch('login', form).then((res) => {
                var data = res.data || {}
                this.$toast(`Bienvenid${data.SALUDO ?? '@'} ${data.NOMBRES} ${data.APELLIDOS}`)
                this.$router.push({name: 'home'})
                return res
            }).catch(err => {
                if(err.response){
                    this.$toast.error('Inicia sesión')
                }
                this.$router.push({name: 'login'})
            })
        },
        checkValidity(){
            if (!this.$refs.userForm){
                this.validateForm = false
            }else{
                this.validateForm = this.$refs.userForm.checkValidity()
            }
        },
    },
    computed: {
        passwordDF(){
            return this.password ? 'password' : 'text'
        },
        passwordConfirmationDF(){
            return this.passwordConfirmation ? 'password' : 'text'
        },
        loading() {
            return this.$store.getters.loading
        },
        errors() {
            return this.$store.getters.errors || {}
        }
    },
    watch:{
        'form':{
            handler(val){
                this.checkValidity()
                return val
            },
            deep: true
        }
    }
}
</script>